import axios from "axios";
import { store } from "../store/store";
import { getResponseMessage } from "../assets/js/utils";

export default {
  async postOrder(tableId, originTableId, isPda, treatOrder, isMembershipPdaOrder, serviceId, orderData, language, pdaDiscount, tableOpeningId, pdaUserId, authToken, jwt) {
    let pdaParameter = ""
    if (isPda) {
      pdaParameter = "&isPda=true"
    }

    let originTableParameter = "";
    if (originTableId != null && originTableId != undefined && originTableId > 0) {
      originTableParameter = `&originTableId=${originTableId}`
    }

    let treatOrderParameter = ""
    if (treatOrder == true) {
      treatOrderParameter = "&treatOrder=true"
    }

    let membershipPdaOrderParameter = "";
    if (isMembershipPdaOrder == true) {
      membershipPdaOrderParameter = "&membershipPdaOrder=true";
    }

    let tableOpeningIdParameter = "";
    if (tableOpeningId != null) {
      tableOpeningIdParameter = `&tableOpeningId=${tableOpeningId}`
    }

    let pdaUserIdIdParameter = "";
    if (pdaUserId != null) {
      pdaUserIdIdParameter = `&pdaUserId=${pdaUserId}`
    }

    let discountParameter = "";
    if (pdaDiscount != null && pdaDiscount != undefined && pdaDiscount > 0) {
      pdaDiscount = pdaDiscount > 100 ? 100 : Number(pdaDiscount).toFixed(2);
      discountParameter = `&pdaDiscount=${pdaDiscount}`
    }

    return axios
      .post(`/order/new?tableId=${tableId}&departmentId=${serviceId}&language=${language}${pdaParameter}${treatOrderParameter}${tableOpeningIdParameter}${pdaUserIdIdParameter}${discountParameter}${membershipPdaOrderParameter}${originTableParameter}`, orderData, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return {
          status: response.status,
          orderId: response.data.id,
          orderStatus: response.data.status,
          scheduled: response.data.scheduled,
          dailyNumber: response.data.dailyOrderNumber,
          tableType: response.data.table.type,
          typeOfTableOpening: response.data.table.typeOfTableOpening,
          products: response.data.products,
          typeOfReceipt: response.data.table.typeOfReceipt,
          automaticallyAccepted: response.data.automaticallyAccepted,
          automaticReceiptPublished: response.data.restaurant.automaticReceiptPublished
        };
      })
      .catch((error) => {
        //setting orderId so 0 so that the orderModal to appear with the error message
        return {
          status: error.response.status,
          orderId: 0,
          orderStatus: "ERROR",
          scheduled: false,
          automaticallyAccepted: false,
          message: getResponseMessage(error.response.data.message)
        };
      });
  },

  async timeOut(orderId) {
    return axios.put(
      `order/time-out?orderId=${orderId}`,
      {},
      {
        headers: {
          "AUTH-TOKEN": store.getters.getUserAuthToken,
          "AUTH-JWT": store.getters.getUserJWT,
        },
      }
    );
  },

  async getPastOrders(page, authToken, jwt) {
    let result = await axios.get(
      `/order/history?page=${page}&resultsPerPage=15`,
      {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      }
    );

    return result.data;
  },

  async getPastOrder(orderId, authToken, jwt) {
    return axios
      .get(`/order?orderId=${orderId}`, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error.response));
  },

  async getLatestPendingOrder(authToken, jwt) {
    return axios
      .get(`/order/latest-pending`, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => console.log(error.response));
  },

  async postReview(review, authToken, jwt) {
    return axios
      .post(
        `/review/new?orderId=${review.id}`,
        {
          comment: review.comment,
          rating: review.rating,
        },
        {
          headers: {
            "AUTH-TOKEN": authToken,
            "AUTH-JWT": jwt,
          },
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response);
        return "Κάτι πήγε λάθος";
      });
  },

  async getReview(orderId) {
    return axios
      .get(`/review?orderId=${orderId}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => console.log(error.response));
  },

  async changeStatus(orderId, restaurantId, token, status) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .put(`${adminBaseUrl}/order/from-user-client/toggle-status?orderId=${orderId}&restaurantId=${restaurantId}&token=${token}&status=${status}`)
      .then((response) => {
        return {
          status: response.status,
          order: response.data,
          orderStatus: response.data.status
        };
      })
      .catch((error) => {
        return {
          status: error.response.status,
          order: null,
          orderStatus: "ERROR",
          errorCode: error.response.data.message,
          message: getResponseMessage(error.response.data.message)
        };
      });
  },

  async sendResponseToUser(orderId, restaurantId, token) {
    return axios
      .post(`/order/send-response-to-user?orderId=${orderId}&restaurantId=${restaurantId}&token=${token}`)
      .then((response) => {
        return {
          status: response.status,
          order: response.data,
          orderStatus: response.data.status
        };
      })
      .catch((error) => {
        return {
          status: error.response.status,
          order: null,
          orderStatus: "ERROR",
          errorCode: error.response.data.message,
          message: getResponseMessage(error.response.data.message)
        };
      });
  },

  async changeExecution(orderId, restaurantId, token, executed) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .put(`${adminBaseUrl}/order/from-user-client/toggle-execution?orderId=${orderId}&restaurantId=${restaurantId}&token=${token}&executed=${executed}`)
      .then((response) => {
        return {
          status: response.status,
          order: response.data,
          orderStatus: response.data.status
        };
      })
      .catch((error) => {
        return {
          status: error.response.status,
          order: null,
          orderStatus: "ERROR",
          errorCode: error.response.data.message,
          message: getResponseMessage(error.response.data.message)
        };
      });
  },

  async getHtmlOrder(orderId, restaurantId, token) {
    return axios
      .get(`/order/html?orderId=${orderId}&restaurantId=${restaurantId}&token=${token}`)
      .then((response) => {
        return {
          status: response.status,
          data: response.data,
          orderStatus: response.data.order.status
        };
      })
      .catch((error) => {
        return {
          status: error.response.status,
          data: null,
          orderStatus: "ERROR",
          errorCode: error.response.data.message,
          message: getResponseMessage(error.response.data.message)
        };
      });
  },

  async getOrdersByTableOpening(tableOpeningId, restaurantId, authToken, jwt) {
    let adminBaseUrl = process.env.VUE_APP_ADMIN_URL;
    return axios
      .get(`${adminBaseUrl}/order/by-table-opening/${tableOpeningId}?restaurantId=${restaurantId}`, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },

  async sendForPrint(orderId, restaurantId, printAllTableOpening, authToken, jwt) {
    return axios
      .post(`/order/send-for-print?orderId=${orderId}&restaurantId=${restaurantId}&printAllTableOpening=${printAllTableOpening}`, null, {
        headers: {
          "AUTH-TOKEN": authToken,
          "AUTH-JWT": jwt,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error.response)
        let errorResponse = {
          error: true
        }
        return errorResponse;
      });
  },
};
